function Spinner() {
    return <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>;
}

/**
 * Use o365.vue.components.Overlay.vue instead.
 */
function Overlay() {
    return <div class="overlay">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
    </div>;
}


/**
 * Use o365.vue.components.ContentEditable.vue instead.
 * Note: OContentEditable is included in CoreComponents plugin.
 * @depricated
 */
const OContentEditable = {
    name: "OContenteditable",
    props: {
        isEditable: null,
        modelValue: null,
        tag: null,
        renderHtml: Boolean,
    },
    emits: ['update:modelValue'],
    currentContent: function () {
        return test;
    },
    tag: {
        default: "span"
    },
    methods: {
        activateEditor() {
            this.$el.focus();
        },
        update: function (e) {
            //this.$emit('update:modelValue',e.target.innerText);
        },
        update2: function (e) {
            console.log('New value: ', e.target.innerText);
            this.$emit('update:modelValue', e.target.innerText);
        },
        onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
        },
        onPaste: function (event) {
            event.preventDefault();
            let text = (event.originalEvent || event).clipboardData.getData('text/plain');
            //Some cleanup needs to be made here
            //if(props.noNL) {
            // text = replaceAll(text, '\r\n', ' ');
            // text = replaceAll(text, '\n', ' ');
            // text = replaceAll(text, '\r', ' ');
            // }
            //as this is dprecated, we need to find clever way of inserting conent into selection
            window.document.execCommand('insertText', false, text);
            //this.$emit('update:modelValue',text);
        },


    },
    render() {
        const DynamicComponent = this.tag ?? 'div';
        return this.renderHtml
            ? <DynamicComponent contenteditable={this.isEditable === undefined ? true : this.isEditable} onInput={this.update} onBlur={this.update2}
                innerHTML={this.modelValue}>
            </DynamicComponent>
            : <DynamicComponent contenteditable={this.isEditable === undefined ? true : this.isEditable} onInput={this.update} onBlur={this.update2}>
                {this.modelValue}
            </DynamicComponent>;
        // return h(DynamicComponent, {
        //     contenteditable: this.isEditable === undefined ? true : this.isEditable,
        //     onInput: this.update,
        //     onBlur: this.update2,
        //     innerHTML: this.renderHtml ?this.modelValue : null
        // }, this.renderHtml ? null : this.modelValue);
    }
}

function BodyWrapper(props, { slots }) {
    const Container = props.is ?? OColContainer;
    return props.disabled
        ? slots.default()
        : <Container class={props.class}>
            {slots.default()}
        </Container>;
}
BodyWrapper.props = { disabled: Boolean, class: null, is: String };
export { BodyWrapper, Spinner, Overlay, OContentEditable };